import React from 'react';
import MotionHoc from './MotionHoc';
import Cards from '../Components/Cards';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

function VideosComponent() {
  return (
    <Container>
      <h1 className="title-header">Videos</h1>
      <Row>
        <Cards />
      </Row>
    </Container>
  );
}

const Videos = MotionHoc(VideosComponent);

export default Videos;
