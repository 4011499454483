//higher order component to add same functionality to each page
import React from 'react';
import { motion } from 'framer-motion';

const MotionHoc = (Component) => {
  return function HOC() {
    return (
      <motion.div
        initial={{ y: 500 }}
        animate={{
          y: 0,
          transition: { duration: 1.2, type: 'spring', ease: 'easeIn' },
        }}
        exit={{
          y: -700,
          transition: { duration: 0.7, type: 'spring', ease: 'easeInOut' },
        }}
      >
        <Component />
      </motion.div>
    );
  };
};

export default MotionHoc;
