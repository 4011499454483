import React from 'react';
import '../App.css';
import './HeroSection.css';
import { Button } from './Button';

function HeroSection() {
  return (
    <div className="hero-container">
      <img
        className="campFire"
        src="/Images/hanifi_under_tree.jpg"
        alt="campFire"
      />
      <img className="logo" src="/Images/happy-hvid.png" alt="logo" />
      <h1>HAPPY OUTDOORS</h1>
      <p>What are you waiting for?</p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline--white"
          buttonSize="btn--large"
          onClick={(e) => {
            window.location.href = 'https://happy-outdoors.myspreadshop.dk/';
          }}
        >
          Shop
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--outline--white"
          buttonSize="btn--large"
          onClick={(e) => {
            window.location.href =
              'https://www.instagram.com/happy.outdoors_dk/';
          }}
        >
          instagram <i class="fa-brands fa-instagram" />
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
