import React from 'react';
import MotionHoc from './MotionHoc';

function AboutComponent() {
  return (
    <>
      <div className="page-container">
        <h1 className="title-header">About</h1>
      </div>
    </>
  );
}

const About = MotionHoc(AboutComponent);

export default About;
