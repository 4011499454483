import React from 'react';
import { Route, Switch, useLocation } from 'react-router';
import Sidebar from './Sidebar';
import Home from './Pages/Home';
import Videos from './Pages/Videos';
import About from './Pages/About';
import Contact from './Pages/Contact';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import './App.css';

const Pages = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function App() {
  const location = useLocation();
  return (
    <>
      <Sidebar />
      <Pages>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route exact path="/" component={Home} />
            <Route path="/Videos" component={Videos} />
            <Route path="/About" component={About} />
            <Route path="/Contact" component={Contact} />
          </Switch>
        </AnimatePresence>
      </Pages>
    </>
  );
}

export default App;
