import React from 'react';
import { Button } from './Button';

function CardItem(props) {
  return (
    <div className="cards_item">
      <a href={props.link} className="cards__item__link">
        <figure className="cards__item__pic-wrap">
          <img src={props.src} alt={props.alt} className="cards__item__img" />
        </figure>
        <div className="cards__item__info">
          <h5 className="cards__item__text">{props.text}</h5>
          <Button
            className="btns"
            buttonStyle="btn--cards"
            buttonSize="btn--medium"
            onClick={(e) => {
              window.location.href = props.link;
            }}
          >
            Watch now
          </Button>
        </div>
      </a>
    </div>
  );
}

export default CardItem;
