import React from 'react';
import MotionHoc from './MotionHoc';

function ContactComponent() {
  return (
    <>
      <div className="page-container">
        <h1 className="title-header">Contact</h1>
      </div>
    </>
  );
}

const Contact = MotionHoc(ContactComponent);

export default Contact;
