import React from 'react';
import CardItem from './CardItem';
import './Cards.css';
import MonsYouthumb from '../Assets/mons_youthumb.jpg';
import ForestCook from '../Assets/forest-cook.jpg';
import EasyOutdoor from '../Assets/Easy-Outdoor.jpg';
import Breakfast from '../Assets/Breakfast.jpg';

function Cards() {
  return (
    <div className="cards">
      <div className="cards__container">
        <CardItem
          // className="cards__item"
          src={MonsYouthumb}
          alt="MonsYouthumb"
          text="Møns Klint - Winter (Timelapse)"
          link="https://www.youtube.com/watch?v=UzEtajmJPiY"
        />
        <CardItem
          // className="cards__item"
          src={ForestCook}
          alt="ForestCook"
          text="Delicious Food In The Woods"
          link="https://www.youtube.com/watch?v=kshI0rTNg5E&t=2s"
        />
        <CardItem
          // className="cards__item"
          src={EasyOutdoor}
          alt="EasyOutdoor"
          text="Easy Outdoor Food On Fire"
          link="https://www.youtube.com/watch?v=rynI80e0KJ0"
        />
        <CardItem
          // className="cards__item"
          src={Breakfast}
          alt="Breakfast"
          text="Breakfast in the woods"
          link="https://www.youtube.com/watch?v=2BjCiPFANYQ&t=132s"
        />
      </div>
    </div>
  );
}

export default Cards;
