import React from 'react';
import MotionHoc from './MotionHoc';
import HeroSection from '../Components/HeroSection';

function HomeComponent() {
  return (
    <>
      <HeroSection />
    </>
  );
}

const Home = MotionHoc(HomeComponent);

export default Home;
